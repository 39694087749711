@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Audiowide";
  src: url('/public/Audiowide-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geo";
  src: url(/public/geo/Geo.otf);
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(89, 89, 89, .1);
}
::-webkit-scrollbar-thumb {
  background: rgba(200, 200, 200, .1);
}

.Audiowide{ 
  font-family: 'Audiowide';
}

.geo {
  font-family: "Geo";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #0d1d31, #263d54);
  /* background-image: radial-gradient(circle, rgba(89, 89, 89, .1) 2px, transparent 0); */
  /* background-image: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%); */
  /* background-size: 15px 15px; */
  /* background-position: 0 0, 15px 15px; */
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
#visuals{
  display: grid;
  grid-template-columns: repeat(0, 1fr);
  grid-template-rows: repeat(0, 1fr);
}

@media (orientation: landscape) {
  #visuals{
    height: 400px;
    width: 400px;
  }
}

@media (orientation: portrait) {
  #visuals{
    height: 90vw;
    width: 90vw;
  }
} */